import { gql } from '@apollo/client';

export const UPDATE_HOSPITAL = gql`
    mutation UpdateHospital(
        $hspId: Int!
        $hspImg: Upload
        $hspName: String
        $hspChief: String
        $hspHospitalNumber: String
        $hspBusinessNumber: String
        $hspAddress: String
        $hspDetailAddress: String
        $hspMedicalDepartment: String
        $hspKakaoChannelId: String
        $hspKakaoChannelUrl: String
        $hspMessageTrId: String
        $hspMessageSendNum: String
    ) {
        updateHospital(
            hsp_id: $hspId
            hsp_img: $hspImg
            hsp_name: $hspName
            hsp_chief: $hspChief
            hsp_hospitalNumber: $hspHospitalNumber
            hsp_businessNumber: $hspBusinessNumber
            hsp_address: $hspAddress
            hsp_detailAddress: $hspDetailAddress
            hsp_medicalDepartment: $hspMedicalDepartment
            hsp_kakaoChannelId: $hspKakaoChannelId
            hsp_kakaoChannelUrl: $hspKakaoChannelUrl
            hsp_messageTrId: $hspMessageTrId
            hsp_messageSendNum: $hspMessageSendNum
        )
    }
`;

export const CREATE_DEFAULT_SCHEDULE = gql`
    mutation CreateDefaultSchedule(
        $dsDay: day
        $dsStartHour: Int
        $dsStartMin: Int
        $dsEndMin: Int
        $dsEndHour: Int
        $dsLunchBreak: Boolean
        $dsLbEndHour: Int
        $dsLbEndMin: Int
        $dsLbStartMin: Int
        $dsLbStartHour: Int
    ) {
        createDefaultSchedule(
            ds_day: $dsDay
            ds_startHour: $dsStartHour
            ds_startMin: $dsStartMin
            ds_endMin: $dsEndMin
            ds_endHour: $dsEndHour
            ds_lunchBreak: $dsLunchBreak
            ds_lbEndHour: $dsLbEndHour
            ds_lbEndMin: $dsLbEndMin
            ds_lbStartMin: $dsLbStartMin
            ds_lbStartHour: $dsLbStartHour
        )
    }
`;

export const UPDATE_DEFAULT_SCHEDULE = gql`
    mutation UpdateDefaultSchedule(
        $dsId: Int!
        $dsDay: day
        $dsStartHour: Int
        $dsStartMin: Int
        $dsEndHour: Int
        $dsEndMin: Int
        $dsLbStartHour: Int
        $dsLunchBreak: Boolean
        $dsLbStartMin: Int
        $dsLbEndHour: Int
        $dsLbEndMin: Int
    ) {
        updateDefaultSchedule(
            ds_id: $dsId
            ds_day: $dsDay
            ds_startHour: $dsStartHour
            ds_startMin: $dsStartMin
            ds_endHour: $dsEndHour
            ds_endMin: $dsEndMin
            ds_lbStartHour: $dsLbStartHour
            ds_lunchBreak: $dsLunchBreak
            ds_lbStartMin: $dsLbStartMin
            ds_lbEndHour: $dsLbEndHour
            ds_lbEndMin: $dsLbEndMin
        )
    }
`;

export const DELETE_DEFAULT_SCHEDULE = gql`
    mutation DeleteDefaultSchedule($dsId: Int!) {
        deleteDefaultSchedule(ds_id: $dsId)
    }
`;

export const CREATE_HOSPITAL_OFF_DAY = gql`
    mutation CreateHospitalOffday(
        $hoType: hospitalOffdayType!
        $hoOffStartDate: String!
        $hoOffEndDate: String!
        $hoOffStartTime: String!
        $hoOffEndTime: String!
        $hoOffdayRepeat: offdayRepeat!
        $hoMemo: String
    ) {
        createHospitalOffday(
            ho_type: $hoType
            ho_offStartDate: $hoOffStartDate
            ho_offEndDate: $hoOffEndDate
            ho_offStartTime: $hoOffStartTime
            ho_offEndTime: $hoOffEndTime
            ho_offdayRepeat: $hoOffdayRepeat
            ho_memo: $hoMemo
        )
    }
`;

export const UPDATE_HOSPITAL_OFF_DAY = gql`
    mutation UpdateHospitalOffday(
        $updateId: Int!
        $aldyoffdayRepeat: offdayRepeat!
        $offdayRepeat: offdayRepeat
        $offStartDate: String
        $offEndDate: String
        $offStartTime: String
        $offEndTime: String
        $memo: String
    ) {
        updateHospitalOffday(
            updateId: $updateId
            aldyoffdayRepeat: $aldyoffdayRepeat
            offdayRepeat: $offdayRepeat
            offStartDate: $offStartDate
            offEndDate: $offEndDate
            offStartTime: $offStartTime
            offEndTime: $offEndTime
            memo: $memo
        )
    }
`;

export const CREATE_DOCTOR_ROOM_SCHEDULE = gql`
    mutation CreateDoctorRoomSchedule(
        $drId: Int!
        $day: day!
        $startHour: Int
        $startMin: Int
        $endHour: Int
        $endMin: Int
        $lunchBreak: Boolean
        $lunchBreakStartMin: Int
        $lunchBreakEndHour: Int
        $lunchBreakEndMin: Int
        $lunchBreakStartHour: Int
    ) {
        createDoctorRoomSchedule(
            dr_id: $drId
            day: $day
            startHour: $startHour
            startMin: $startMin
            endHour: $endHour
            endMin: $endMin
            lunchBreak: $lunchBreak
            lunchBreakStartMin: $lunchBreakStartMin
            lunchBreakEndHour: $lunchBreakEndHour
            lunchBreakEndMin: $lunchBreakEndMin
            lunchBreakStartHour: $lunchBreakStartHour
        )
    }
`;

export const UPDATE_DOCTOR_ROOM_SCHEDULE = gql`
    mutation UpdateDoctorRoomSchedule(
        $drsId: Int!
        $day: day
        $startHour: Int
        $startMin: Int
        $endHour: Int
        $endMin: Int
        $lunchBreak: Boolean
        $lunchBreakStartHour: Int
        $lunchBreakStartMin: Int
        $lunchBreakEndHour: Int
        $lunchBreakEndMin: Int
    ) {
        updateDoctorRoomSchedule(
            drs_id: $drsId
            day: $day
            startHour: $startHour
            startMin: $startMin
            endHour: $endHour
            endMin: $endMin
            lunchBreak: $lunchBreak
            lunchBreakStartHour: $lunchBreakStartHour
            lunchBreakStartMin: $lunchBreakStartMin
            lunchBreakEndHour: $lunchBreakEndHour
            lunchBreakEndMin: $lunchBreakEndMin
        )
    }
`;

export const DELETE_DOCTOR_ROOM_SCHEDULE = gql`
    mutation DeleteDoctorRoomSchedule($drsId: Int!) {
        deleteDoctorRoomSchedule(drs_id: $drsId)
    }
`;

export const DELETE_HOSPITAL_OFF_DAY = gql`
    mutation DeleteHospitalOffday(
        $deleteId: Int!
        $aldyoffdayRepeat: offdayRepeat!
    ) {
        deleteHospitalOffday(deleteId: $deleteId, aldyoffdayRepeat: $aldyoffdayRepeat)
    }
`;

export const UPDATE_ALIM_SET = gql`
    mutation UpdateResAlimSet(
        $asId: Int!
        $asType: resAlimType
        $asTime1: Boolean
        $asTime2: Boolean
        $asTime3: Boolean
        $asTime4: Boolean
        $asTemplateId: Int
    ) {
        updateResAlimSet(
            as_id: $asId
            as_type: $asType
            as_time1: $asTime1
            as_time2: $asTime2
            as_time3: $asTime3
            as_time4: $asTime4
            as_templateId: $asTemplateId
        )
    }
`;

export const LINKED_CHAT_BOT_ADMIN_PAGE = gql`
    mutation Mutation {
        linkedChatbotAdminPage
    }
`;
